var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{ref:"modalPasswordRecovery",attrs:{"id":"modalPasswordRecovery","size":"lg","centered":"","hide-backdrop":"","hide-footer":"","content-class":"shadow","ok-only":"","no-stacking":""}},[_c('div',{staticClass:"row mt-10"},[_c('div',{staticClass:"col-12 col-md-5 px-10"},[_c('img',{staticClass:"h-60px my-4",attrs:{"src":_vm.getOrganization.data
            ? _vm.getOrganization.data.logo1Uri
            : '/assets/media/bipart/logo-bipart-brand.svg',"alt":"Logo"}}),_c('div',{staticClass:"h4 mt-5 py-5 border-top border-top-2 dashed text-primary"},[_vm._v(" "+_vm._s(_vm.getOrganization.data.name)+" ")])]),_c('div',{staticClass:"col-12 col-md-7 border-left px-10"},[_c('div',{staticClass:"h4 text-primary"},[_vm._v(" "+_vm._s(_vm.$t("PASSWORDRECOVERY.RECUPEROPASSWORD"))+" ")]),_c('form',{staticClass:"form mt-5 mb-5"},[_c('div',{staticClass:"form-group mb-5"},[_c('p',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.$t("PASSWORDRECOVERY.SCELTAMETODO"))+" ")]),_c('div',{staticClass:"input-group align-items-center"},[_c('b-form-select',{staticClass:"rounded-0 border-0 shadow-0 bg-white",class:{
                invalid: _vm.$v.form.method.$dirty && _vm.$v.form.method.$invalid,
              },attrs:{"id":"method","state":_vm.validateState('method'),"options":_vm.methodsOptions},model:{value:(_vm.$v.form.method.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.method, "$model", $$v)},expression:"$v.form.method.$model"}})],1)]),(_vm.$v.form.method.$dirty && _vm.$v.form.method.$invalid)?[(!_vm.$v.form.method.greaterThanZero)?_c('div',{staticClass:"error pt-2 w-100"},[_vm._v(" "+_vm._s(_vm.$t("PASSWORDRECOVERY.SCELTAMETODONECESSARIA"))+" ")]):_vm._e()]:_vm._e(),_c('div',{staticClass:"form-group mb-5"},[_c('div',{staticClass:"input-group align-items-center"},[_c('div',{staticClass:"input-group-prepend"},[_c('b-form-select',{staticClass:"rounded-0 border-0 shadow-0 bg-white",class:{
                  invalid: _vm.$v.form.prefix.$dirty && _vm.$v.form.prefix.$invalid,
                },attrs:{"id":"prefix","state":_vm.validateState('prefix'),"options":_vm.options},model:{value:(_vm.$v.form.prefix.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.prefix, "$model", $$v)},expression:"$v.form.prefix.$model"}})],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.mobile.$model),expression:"$v.form.mobile.$model"}],ref:"mobile",staticClass:"form-control h-auto form-control-solid py-4 px-8",class:{
                invalid: _vm.$v.form.mobile.$dirty && _vm.$v.form.mobile.$invalid,
              },attrs:{"type":"text","placeholder":_vm.$t('PASSWORDRECOVERY.INSERISCICELLULARE'),"name":"mobile","id":"mobile","state":_vm.validateState('mobile'),"aria-describedby":"username-live-feedback","autocomplete":"off"},domProps:{"value":(_vm.$v.form.mobile.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.form.mobile, "$model", $event.target.value)}}})]),(_vm.$v.form.prefix.$dirty && _vm.$v.form.prefix.$invalid)?[(!_vm.$v.form.prefix.required)?_c('div',{staticClass:"error pt-2 w-100"},[_vm._v(" "+_vm._s(_vm.$t("PASSWORDRECOVERY.PHONEERROR1"))+" ")]):_vm._e()]:_vm._e(),(_vm.$v.form.mobile.$dirty && _vm.$v.form.mobile.$invalid)?[(!_vm.$v.form.mobile.required)?_c('div',{staticClass:"error w-100 pt-2"},[_vm._v(" "+_vm._s(_vm.$t("PASSWORDRECOVERY.PHONEERROR2"))+" ")]):_vm._e(),(!_vm.$v.form.mobile.mobileIsValid)?_c('div',{staticClass:"error w-100 pt-2"},[_vm._v(" "+_vm._s(_vm.$t("PASSWORDRECOVERY.PHONEERROR3"))+" ")]):_vm._e()]:_vm._e()],2),_c('div',{staticClass:"form-group d-flex flex-wrap"},[_c('b-button',{ref:"mobile_submit",staticClass:"ml-2 btn btn-lg btn-primary text-uppercase font-weight-bolder",attrs:{"id":"mobile_submit"},on:{"click":function($event){$event.preventDefault();return _vm.checkMobile.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t("PASSWORDRECOVERY.CONFERMA")))])],1)],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }