<template>
  <b-modal
      id="modal-password-change"
      ref="modalPasswordChange"
      size="lg" centered hide-backdrop hide-footer content-class="shadow" ok-only no-stacking
  >
    <div class="row mt-10">
      <div class="col-12 col-md-5 px-10">
        <img
            :src="(getOrganization.data) ? getOrganization.data.logo1Uri : '/assets/media/bipart/logo-bipart-brand.svg'"
            class="h-60px my-4"
            alt="Logo"
        />
        <div class="h4 mt-5 py-5 border-top border-top-2 dashed text-primary">{{ getOrganization.data.name }}</div>
      </div>
      <div class="col-12 col-md-7 border-left px-10">
        <div class="h4 text-primary">
          {{$t("PASSWORDRECOVERY.CAMBIAPASSWORD")}}
        </div>
        <form class="form mt-5 mb-5">

          <div class="form-group mb-5">
            <div class="input-group">
              <input
                  class="form-control w-100 h-auto form-control-solid py-4 px-8 mb-2"
                  type="text"
                  v-bind:placeholder="$t('PASSWORDRECOVERY.INSERISCIPASSWORD')"
                  name="password"
                  id="password"
                  ref="password"
                  v-model="$v.form.password.$model"
                  :state="validateState('password')"
                  aria-describedby="username-live-feedback"
                  autocomplete="off"
                  :class="{
                      invalid: $v.form.password.$dirty && $v.form.password.$invalid
                    }"
              />
              <template
                  v-if="$v.form.password.$dirty && $v.form.password.$invalid"
              >
                  <span v-if="!$v.form.password.required" class="error">
                    {{this.$t("PASSWORDRECOVERY.INSERISCIPASSWORD")}}
                  </span>
                  <span v-if="!$v.form.password.minLength" class="error">
                    {{this.$t("PASSWORDRECOVERY.ERRORPWLENGTH")}}
                  </span>
              </template>
            </div>
            <div class="input-group">
              <input
                  class="form-control w-100 h-auto form-control-solid py-4 px-8 mb-2"
                  type="text"
                  v-bind:placeholder="$t('PASSWORDRECOVERY.CONFERMAPASSWORD')"
                  name="passwordConfirm"
                  id="passwordConfirm"
                  ref="passwordConfirm"
                  v-model="$v.form.passwordConfirm.$model"
                  :state="validateState('passwordConfirm')"
                  aria-describedby="username-live-feedback"
                  autocomplete="off"
                  :class="{
                      invalid: $v.form.passwordConfirm.$dirty && $v.form.passwordConfirm.$invalid
                    }"
              />
              <template
                  v-if="$v.form.passwordConfirm.$dirty && $v.form.passwordConfirm.$invalid"
              >
                  <span v-if="!$v.form.passwordConfirm.sameAsPassword" class="error">
                    {{this.$t("PASSWORDRECOVERY.ERRORPWEQUAL")}}
                  </span>
              </template>
            </div>
          </div>

          <div class="form-group d-flex flex-wrap">
            <b-button
                class="ml-2 btn btn-lg btn-primary text-uppercase font-weight-bolder"
                @click.prevent="checkPassword"
                id="password_submit"
                ref="password_submit"
            >
              {{this.$t("PASSWORDRECOVERY.CONFERMA")}}
            </b-button>

          </div>

        </form>
      </div>
    </div>
  </b-modal>

</template>

<script>
import {validationMixin} from "vuelidate";
import {commonMixin} from "@/mixins/common";
import {mapGetters} from "vuex";
import {required, minLength, sameAs} from "vuelidate/lib/validators";
import {CHANGE_PASSWORD} from "@/core/services/store/auth.module";
import Swal from "sweetalert2";
// import i18nService from "@/core/services/i18n.service";

export default {
name: "PasswordChange",
  mixins: [validationMixin, commonMixin],
  data() {
    return {
      form: {
        password: "",
        passwordConfirm: ""
      },
    };
  },
  computed: {
    ...mapGetters([
      "getOrganization",
      "currentUser"
    ]),
  },
  mounted() {},
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(6)
      },
      passwordConfirm: {
        sameAsPassword: sameAs('password')
      }
    },
  },
  methods: {
    close() {
      this.$refs["modalPasswordChange"].hide();
    },
    checkPassword() {
      const pw = this.form.password.trim();
      if(pw === this.form.passwordConfirm.trim()) {
        this.onSubmit();
      } else {
        console.log('Password differenti');
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    async onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      // set spinner to submit button
      const submitButton = this.$refs["password_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      let newData = {
        id: this.currentUser.id,
        password: this.$v.form.password.$model,
      }
      console.log("Dati inviati: ", newData);

      await this.$store
          .dispatch(CHANGE_PASSWORD, newData)
          .then(response => {
            console.log('Risposta dalla promise CHANGE_PASSWORD: ', response);
            if (response.status == 200) {
              this.$refs['modalPasswordChange'].hide();

              Swal.fire(
                  "Success",
                  this.$t("PASSWORDRECOVERY.RECUPERORIUSCITO"),
                  "info"
              );

              // setTimeout(() => {
              //   // Invio l'utente alla home
              //   this.$router.push({ name: "intro" });
              // }, 500);

            } else if(response.status == 400) {
              this.manageAxiosErrors(response);
            }  else {
              Swal.fire(
                  "Error",
                  "Ops... something went wrong; Status code: " + response.status,
                  "info"
              );
            }
            submitButton.classList.remove("spinner","spinner-light","spinner-right");
          })
          .catch(err => {
            this.manageAxiosErrors(err);
            submitButton.classList.remove("spinner", "spinner-light", "spinner-right");
          });

    }
  }
}
</script>

<style scoped>
  .error{
    color:#f00;
  }
  .invalid {
    border-color: #f00;
  }
</style>
