<template>
  <b-modal
    id="modalPasswordRecovery"
    ref="modalPasswordRecovery"
    size="lg"
    centered
    hide-backdrop
    hide-footer
    content-class="shadow"
    ok-only
    no-stacking
  >
    <div class="row mt-10">
      <div class="col-12 col-md-5 px-10">
        <img
          :src="
            getOrganization.data
              ? getOrganization.data.logo1Uri
              : '/assets/media/bipart/logo-bipart-brand.svg'
          "
          class="h-60px my-4"
          alt="Logo"
        />
        <div class="h4 mt-5 py-5 border-top border-top-2 dashed text-primary">
          {{ getOrganization.data.name }}
        </div>
      </div>
      <div class="col-12 col-md-7 border-left px-10">
        <div class="h4 text-primary">
          {{ $t("PASSWORDRECOVERY.RECUPEROPASSWORD") }}
        </div>

        <form class="form mt-5 mb-5">
          <div class="form-group mb-5">
            <p class="font-weight-bolder">
              {{ $t("PASSWORDRECOVERY.SCELTAMETODO") }}
            </p>
            <div class="input-group align-items-center">
              <b-form-select
                id="method"
                v-model="$v.form.method.$model"
                :state="validateState('method')"
                :options="methodsOptions"
                :class="{
                  invalid: $v.form.method.$dirty && $v.form.method.$invalid,
                }"
                class="rounded-0 border-0 shadow-0 bg-white"
              ></b-form-select>
            </div>
          </div>
          <template v-if="$v.form.method.$dirty && $v.form.method.$invalid">
            <div
              v-if="!$v.form.method.greaterThanZero"
              class="error pt-2 w-100"
            >
              {{ $t("PASSWORDRECOVERY.SCELTAMETODONECESSARIA") }}
            </div>
          </template>

          <div class="form-group mb-5">
            <div class="input-group align-items-center">
              <div class="input-group-prepend">
                <b-form-select
                  id="prefix"
                  v-model="$v.form.prefix.$model"
                  :state="validateState('prefix')"
                  :options="options"
                  :class="{
                    invalid: $v.form.prefix.$dirty && $v.form.prefix.$invalid,
                  }"
                  class="rounded-0 border-0 shadow-0 bg-white"
                ></b-form-select>
              </div>
              <input
                class="form-control h-auto form-control-solid py-4 px-8"
                type="text"
                v-bind:placeholder="$t('PASSWORDRECOVERY.INSERISCICELLULARE')"
                name="mobile"
                id="mobile"
                ref="mobile"
                v-model="$v.form.mobile.$model"
                :state="validateState('mobile')"
                aria-describedby="username-live-feedback"
                autocomplete="off"
                :class="{
                  invalid: $v.form.mobile.$dirty && $v.form.mobile.$invalid,
                }"
              />
            </div>
            <template v-if="$v.form.prefix.$dirty && $v.form.prefix.$invalid">
              <div v-if="!$v.form.prefix.required" class="error pt-2 w-100">
                {{ $t("PASSWORDRECOVERY.PHONEERROR1") }}
              </div>
            </template>
            <template v-if="$v.form.mobile.$dirty && $v.form.mobile.$invalid">
              <div v-if="!$v.form.mobile.required" class="error w-100 pt-2">
                {{ $t("PASSWORDRECOVERY.PHONEERROR2") }}
              </div>
              <div
                v-if="!$v.form.mobile.mobileIsValid"
                class="error w-100 pt-2"
              >
                {{ $t("PASSWORDRECOVERY.PHONEERROR3") }}
              </div>
            </template>
          </div>

          <div class="form-group d-flex flex-wrap">
            <b-button
              class="ml-2 btn btn-lg btn-primary text-uppercase font-weight-bolder"
              @click.prevent="checkMobile"
              id="mobile_submit"
              ref="mobile_submit"
              >{{ $t("PASSWORDRECOVERY.CONFERMA") }}</b-button
            >
          </div>
        </form>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { validationMixin } from "vuelidate";
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import { RECOVER_ACCOUNT_BY_MOBILE } from "@/core/services/store/auth.module";
// import {RECOVER_ACCOUNT_BY_MOBILE} from "@/core/services/store/auth.module";
import Swal from "sweetalert2";
import { commonMixin } from "@/mixins/common";
import i18nService from "@/core/services/i18n.service";

const mobileRegex = new RegExp("^[0-9\\s]{10,15}$");
const mobileIsValid = (value) => mobileRegex.test(value);
const greaterThanZero = (value) => value > 0;

export default {
  name: "PasswordRecovery",
  mixins: [validationMixin, commonMixin],
  data() {
    return {
      selected: null,
      options: [{ value: "+39", text: "+39" }],
      methodsOptions: [
        // {value: 0, text: this.$t("PASSWORDRECOVERY.METODORECUPERO")},
        { value: 1, text: this.$t("PASSWORDRECOVERY.METODOCELLULARE") },
        // {value: 2, text: 'mail'},
      ],
      form: {
        method: 1,
        prefix: "+39",
        mobile: "",
      },
    };
  },
  computed: {
    ...mapGetters(["getOrganization", "currentUser"]),
  },
  mounted() {
    // Se non è l'utente anonimo recupero lo stato dei flag dell'utente
    // if(this.currentUser.id !== 5 ) {
    //   this.privacy = this.currentUser.privacyPolicyAccepted;
    //   this.term_and_conditions = this.currentUser.termsConditionsAccepted;
    // }
  },
  validations: {
    form: {
      method: {
        greaterThanZero,
      },
      prefix: {
        required,
      },
      mobile: {
        required,
        mobileIsValid,
      },
    },
  },
  methods: {
    // checkUser() {
    //   // Se non è l'utente anonimo recupero lo stato dei flag dell'utente
    //   if(this.currentUser.id !== 5 ) {
    //     // this.isDisabledCheck = true;
    //   }
    // },
    // resetForm() {
    //   this.form = {
    //     prefix: null,
    //     mobile: ""
    //   };
    //
    //   this.$nextTick(() => {
    //     this.$v.$reset();
    //   });
    // },
    // close() {
    //   this.$refs["modalPasswordRecovery"].hide();
    // },
    checkMobile() {
      console.log("checked");
      this.onSubmit();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    async onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      // set spinner to submit button
      const submitButton = this.$refs["mobile_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // validazione del numero superata
      this.$refs["mobile"].classList.add("is-valid");

      //## Chiamata di invio numero cellulare
      let mobileData = {
        organizationDomain: this.$currentDomain,
        mobile: this.$v.form.prefix.$model + " " + this.$v.form.mobile.$model, //"+39 333 1234567",
        locale: i18nService.getActiveLanguage(),
        // fakeSmsCode: "987654"
      };
      console.log("mobile: ", mobileData);

      await this.$store
        .dispatch(RECOVER_ACCOUNT_BY_MOBILE, mobileData)
        .then((response) => {
          console.log("Risposta dalla promise register in then: ", response);
          if (response.status == 200 || response.status == 201) {
            if (response.headers.token == "BadCredentialsException") {
              Swal.fire(
                this.$t("PASSWORDRECOVERY.ALERTPHONE1TITLE"),
                this.$t("PASSWORDRECOVERY.ALERTPHONE1HTML"),
                "info"
              );
            } else {
              // this.$store.dispatch(REFRESH_TOKEN, response.headers.token);
              // this.$store.dispatch(SET_HEADER_TOKEN)
              this.$emit("switchToOTPVerify");
            }
            this.$refs["modalPasswordRecovery"].hide();
          } else if (response.status == 400) {
            this.manageAxiosErrors(response);
          } else {
            Swal.fire(
              "Error",
              "Ops... something went wrong; Status code: " + response.status,
              "info"
            );
          }
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        })
        .catch((err) => {
          this.manageAxiosErrors(err);
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        });
    },
  },
};
</script>

<style scoped>
.error {
  color: #f00;
}
.invalid {
  border-color: #f00;
}
select#prefix.custom-select.is-valid {
  background-image: none;
}
</style>
